import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { AdminService } from '@services/admin.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PagedData } from '@admin/finance/finance.service';

export type exclusionRequest = {
  club_id?: number;
  name?: string;
  notes: string;
  rec_no: number;
  expires?: Date;
};

export type exclusionResponse = exclusionRequest & {
  bill: {
    category: string;
    fees: number;
    locker: number;
    outstanding: number;
    subscription: number;
  };
  bill_member: {
    email: string;
    name: string;
    person_id: number;
    arrangement: any;
  };
};

export type ExclusionFilter = {
  memberSearch?: string;
  filterType?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ExceptionsService {
  constructor(private http: HttpClient, private adminSvc: AdminService) {}

  add(v: exclusionRequest): any {
    return this.adminSvc.singleClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.post(`api/payments/${club_id}/exclusions`, v)
      )
    );
  }

  list({
    pager,
    sorter,
    filter,
  }: {
    pager?: PageEvent;
    sorter: Sort;
    filter?: ExclusionFilter | null;
  }) {
    const params: {
      [param: string]:
        | string
        | number
        | boolean
        | ReadonlyArray<string | number | boolean>;
    } =
      pager != null
        ? { pageSize: pager.pageSize, pageIndex: pager.pageIndex }
        : {};
    if (sorter.direction != '') {
      params['sortBy'] = sorter.active;
      params['direction'] = sorter.direction;
    }
    if (filter && filter.memberSearch && filter.memberSearch != '') {
      params['memberSearch'] = filter.memberSearch;
    }
    if (filter && filter.filterType && filter.filterType != '') {
      params['filterType'] = filter.filterType;
    }
    return this.adminSvc.activeClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.get<PagedData<exclusionResponse>>(
          `api/payments/${club_id}/exclusions-paged`,
          { params }
        )
      )
    );
  }

  delete(row: exclusionRequest) {
    return this.adminSvc.singleClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.delete(`api/payments/${club_id}/exclusions/${row.rec_no}`)
      )
    );
  }
}
